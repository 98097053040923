import 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { getColor, rgbaColor } from 'helpers/utils';
import React, { useEffect, useState, useRef } from 'react';
import { Card } from 'react-bootstrap';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import axios from 'axios';
import Loading from 'components/common/Loading';
import { textAppNames } from 'helpers/utils';

async function formatData(logs) {
  if (!logs || Object.keys(logs).length == 0) return;

  const totalCounts = Object.values(logs).reduce((acc, sources) => {
    Object.entries(sources).forEach(([source, count]) => {
      acc[source] = (acc[source] || 0) + count;
    });
    return acc;
  }, {});

  const labels = [];
  const values = [];

  for (const source of Object.keys(totalCounts)) {
    labels.push(source);
    values.push(totalCounts[source]);
  }

  const data = {
    labels: labels,
    datasets: [
      {
        label: '# of Accounts',
        data: values,
        accounts: logs,
        backgroundColor: [
          rgbaColor(getColor('success'), 0.7),
          rgbaColor(getColor('danger'), 0.7),
          rgbaColor(getColor('warning'), 0.7),
          rgbaColor(getColor('light'), 0.7)
        ]
      }
    ]
  };

  return data;
}

const SourcesBarChart = ({
  app,
  height,
  logs,
  setModalTitle,
  setModalAccounts
}) => {
  const [data, setData] = useState();

  useEffect(() => {
    formatData(logs).then(d => setData(d));
  }, [logs]);

  const chartRef = useRef();
  // const chartOnClick = event => {
  //   const col = getElementAtEvent(chartRef.current, event)[0];
  //
  //   if (!col) {
  //     return;
  //   }
  //
  //   setModalTitle(`${data.labels[col.index]} - Sources`);
  //   setModalAccounts(data.datasets[0].accounts);
  // };

  const options = {
    indexAxis: 'y',
    responsive: true,
    barThickness: 20,
    maintainAspectRatio: false,
    animation: {
      duration: 2000
    },
    scales: {
      y: {
        ticks: {
          display: true,
          font: {
            size: 12
          }
        }
      },
      x: {
        ticks: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false
      },
      title: {
        display: false
      },
      datalabels: {
        anchor: 'end',
        align: 'left',
        color: '#000',
        font: {
          size: 12
        }
      }
    }
  };

  if (data == null || data.length == 0) {
    return (
      <Card style={{ height: height }}>
        <Card.Body style={{ paddingTop: 0 }}>
          <div className="chart-header">
            <h2>{textAppNames[app]} Customers (Tiers)</h2>
          </div>
          <Loading />
        </Card.Body>
      </Card>
    );
  }

  return (
    <Card style={{ height: height, overflowY: 'auto' }}>
      <Card.Body style={{ paddingTop: 0 }}>
        <div className="chart-header">
          <h2>{textAppNames[app]} Usage</h2>
        </div>
        <div style={{ height: 30 * data.labels.length }}>
          <Bar
            ref={chartRef}
            // onClick={chartOnClick}
            options={options}
            data={data}
            plugins={[ChartDataLabels]}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default SourcesBarChart;
