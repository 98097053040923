import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Alert, Form, Table } from 'react-bootstrap';
import axios from 'axios';
import Lottie from 'lottie-react';
import PropTypes from 'prop-types';
import loading from 'assets/animations/loading.json';

const AdminUserSettings = props => {
  const [users, setUsers] = useState([]);
  const [addNewUserModal, setAddNewUserModal] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [alertVariant, setAlertVariant] = useState('');
  const [alertText, setAlertText] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [userToRemove, setUserToRemove] = useState('');
  const [removeModalText, setRemoveModalText] = useState();

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = () => {
    console.log({ accountId: props.accountID });
    if (props.accountID) {
      axios.get(`/pmladmin/users?accountID=${props.accountID}`).then(res => {
        setUsers(res.data);
      });
    }
  };

  const addNewUser = async () => {
    setModalLoading(true);
    setShowAlert(false);
    setEmail('');
    let res = await axios.post(
      `/pmladmin/users?accountID=${props.accountID}&email=${email}`,
      {}
    );
    setAlertText(res.data);
    if (res.data == 'User added successfully') {
      setAlertVariant('success');
    } else {
      setAlertVariant('danger');
    }
    setShowAlert(true);
    fetchUsers();
    setModalLoading(false);
  };

  const removeUser = async () => {
    setModalLoading(true);
    let res = await axios.delete(
      `/pmladmin/users?accountID=${props.accountID}&userID=${userToRemove.UserID}`,
      {}
    );
    setRemoveModalText(res.data);
    fetchUsers();
    setModalLoading(false);
  };

  const toggleUserAdmin = async user => {
    await axios.post(
      `/pmladmin/users/toggleadmin?accountID=${props.accountID}&email=${user.Email}`,
      {}
    );
    fetchUsers();
  };

  if (users.length == 0) {
    return (
      <Lottie
        animationData={loading}
        loop
        autoplay
        style={{ width: '200px', height: '200px', margin: 'auto' }}
      />
    );
  }

  return (
    <>
      {/* //* Add new user moda/ */}
      <Modal
        show={addNewUserModal}
        onHide={() => {
          setAddNewUserModal(false);
          setShowAlert(false);
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add New User
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Alert variant={alertVariant} hidden={!showAlert}>
            {alertText}
          </Alert>
          <div hidden={modalLoading}>
            <p>If the user exists, they will be added to your portal.</p>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={email}
                onChange={e => setEmail(e.target.value)}
              />
            </Form.Group>
          </div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setAddNewUserModal(false)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => addNewUser()}>
            Add User
          </Button>
        </Modal.Footer>
      </Modal>

      {/* //*Delete user modal */}
      <Modal
        show={removeUserModal}
        onHide={() => {
          setRemoveUserModal(false);
          setRemoveModalText(
            <p>
              Are you sure you want to remove <b>{userToRemove.Email}?</b>
            </p>
          );
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div hidden={modalLoading}>{removeModalText}</div>
          <Lottie
            animationData={loading}
            loop
            autoplay
            style={{ width: '100px', height: '100px', margin: 'auto' }}
            hidden={!modalLoading}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setRemoveUserModal(false)}>
            Close
          </Button>
          <Button
            variant="danger"
            onClick={e => {
              removeUser();
              e.target.hidden = true;
            }}
          >
            Remove User
          </Button>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Body>
          <div className="horizontalDiv">
            <h2 style={{ fontWeight: 'normal', fontSize: 24, paddingLeft: 16 }}>
              Manage Users
            </h2>
            <Button onClick={() => setAddNewUserModal(true)} variant="danger">
              Add New User
            </Button>
          </div>
          <Table responsive hover>
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col">Email</th>
                <th className="text-end" scope="col">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {users.map((user, idx) => {
                return (
                  <tr key={idx}>
                    <td>
                      {user.FirstName} {user.LastName}
                    </td>
                    <td>{user.Email}</td>
                    <td className="text-end">
                      {user.Email !== localStorage.getItem('email') && (
                        <>
                          <Button
                            variant="danger"
                            onClick={() => toggleUserAdmin(user)}
                            style={{ marginRight: 8 }}
                          >
                            {user.isAdmin ? 'Remove' : 'Set'} as Admin
                          </Button>

                          <Button
                            variant="primary"
                            onClick={() => {
                              setRemoveUserModal(true);
                              setUserToRemove(user);
                              setRemoveModalText(
                                <p>
                                  Are you sure you want to remove{' '}
                                  <b>{user.Email}?</b>
                                </p>
                              );
                            }}
                          >
                            Remove
                          </Button>
                        </>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    </>
  );
};

AdminUserSettings.propTypes = {
  accountID: PropTypes.number
};

export default AdminUserSettings;
