import axios from 'axios';
import { defaults } from 'chart.js';
import PageTitle from 'components/common/PageTitle';
import NavbarTop from 'components/navbar/top/NavbarTop';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import AccountsModal from './reportComponents/AccountsModal';
import BigNumber from './reportComponents/BigNumber';
import MonthlyRevenueLineChart from './reportComponents/MonthlyRevenueLineChart';
import MonthlyTransactionsTrend from './reportComponents/MonthlyTransactionsTrend';
import SourcesBarChart from './reportComponents/SourcesBarChart';
import TiersBarChart from './reportComponents/TiersBarChart';
import TransactionsModal from './reportComponents/TransactionsModal';
import TrialConversionModal from './reportComponents/TrialConversionModal';
import TrialsBarChart from './reportComponents/TrialsBarChart';
import UsageTable from './reportComponents/UsageTable';

const APP_NAME = 'CurrencyR8';

const fetchPayingCustomers = async () => {
  try {
    let res = (
      await axios.get(
        `/pmladmin/reports/current-paying-customers?app=${APP_NAME}`
      )
    ).data;
    return res;
  } catch {
    return [];
  }
};

const fetchTransactions = async () => {
  let now = new Date();

  let date12MonthsAgo = new Date();
  date12MonthsAgo.setMonth(now.getMonth() - 12);
  if (now.getMonth() < date12MonthsAgo.getMonth()) {
    date12MonthsAgo.setFullYear(now.getFullYear() - 1);
  }

  try {
    let res = (
      await axios.get(
        `/pmladmin/reports/app-transactions?app=${APP_NAME}&to=${now.toISOString()}&from=${date12MonthsAgo.toISOString()}`
      )
    ).data;
    return res;
  } catch {
    return [];
  }
};

const fetchSources = async () => {
  try {
    let res = (await axios.get(`/pmladmin/reports/log-sources?app=${APP_NAME}`))
      .data;
    return res;
  } catch {
    return [];
  }
};

const getAccountTrialsPurchase = transactions => {
  const accounts = {};

  for (let t of transactions) {
    if (!accounts[t.AccountID]) {
      accounts[t.AccountID] = {};
    }
    if (t.NetAmount > 0) {
      accounts[t.AccountID].firstPurchase = t;
    }
    if (t.Description.includes('Free Trial')) {
      accounts[t.AccountID].trial = t;
    }
  }

  return accounts;
};

const CurrencyR8Report = () => {
  defaults.font.family = 'Poppins';

  const [payingCustomers, setPayingCustomers] = useState(undefined);
  const [transactions, setTransactions] = useState(undefined);
  const [sources, setSources] = useState(undefined);
  const [accountTrialsPurchase, setAccountTrialsPurchase] = useState(undefined);

  const [modalTransactions, setModalTransactions] = useState(null);
  const [transactionsModalTitle, setTransactionsModalTitle] = useState(null);

  const [modalAccounts, setModalAccounts] = useState(null);
  const [accountsModalTitle, setAccountsModalTitle] = useState(null);

  const [modalTrialConversions, setModalTrialConversions] = useState(null);
  const [trialConversionsModalTitle, setTrialConversionsTitle] = useState(null);

  const [trialTransactions, setTrialTransactions] = useState(undefined);
  const [newCustomersTransactions, setNewCustomersTransactions] =
    useState(undefined);

  const [exchangeRates, setExchangeRates] = useState(null);

  useEffect(() => {
    fetchPayingCustomers().then(d => setPayingCustomers(d));
    fetchTransactions().then(d => setTransactions(d));
    fetchSources().then(d => setSources(d));

    axios
      .get('/pmladmin/reports/exchange-rates')
      .then(d => setExchangeRates(d.data));
  }, []);

  useEffect(() => {
    if (transactions?.length > 0) {
      setTrialTransactions(
        transactions.filter(t => t.Description.includes('Free Trial'))
      );

      setAccountTrialsPurchase(getAccountTrialsPurchase(transactions));

      // Calculate new customers last 30 days
      const groupedByCustomer = transactions.reduce((acc, obj) => {
        if (!acc[obj.AccountID]) {
          acc[obj.AccountID] = [];
        }
        acc[obj.AccountID].push(obj);
        return acc;
      }, {});

      const newCustomersLast30Days = [];

      for (const c in groupedByCustomer) {
        if (!c || c == 'null') continue;
        const customer = groupedByCustomer[c];
        const firstPurchase = customer
          .filter(t => t.NetAmount != 0)
          .sort((a, b) => new Date(a.Timestamp) - new Date(b.Timestamp))[0];

        if (!firstPurchase) continue;

        if (
          new Date(firstPurchase.Timestamp) >
          new Date(new Date().setDate(new Date().getDate() - 30))
        ) {
          newCustomersLast30Days.push(firstPurchase);
        }
      }

      setNewCustomersTransactions(newCustomersLast30Days);
    }
  }, [transactions]);

  return (
    <>
      <NavbarTop simple={true} />
      <TransactionsModal
        title={transactionsModalTitle}
        transactions={modalTransactions}
        setTransactions={setModalTransactions}
        exchangeRates={exchangeRates}
        app={APP_NAME}
      />
      <AccountsModal
        title={accountsModalTitle}
        accounts={modalAccounts}
        setAccounts={setModalAccounts}
        app={APP_NAME}
      />
      <TrialConversionModal
        title={trialConversionsModalTitle}
        accounts={modalTrialConversions}
        setAccounts={setModalTrialConversions}
        app={APP_NAME}
      />

      <PageTitle app="Currency-R8" page="Reporting Dashboard" />
      <Row className="mb-3">
        <Col md={2}>
          <BigNumber
            title={`Current ${APP_NAME} Customers`}
            number={payingCustomers?.length}
            onClick={() => {
              setAccountsModalTitle(`Current ${APP_NAME} Customers`);
              setModalAccounts(payingCustomers);
            }}
          />
        </Col>{' '}
        <Col md={2}>
          <BigNumber
            title="New Customers Last 30 Days"
            number={newCustomersTransactions?.length}
            onClick={() => {
              setTransactionsModalTitle('New Customers Last 30 Days');
              setModalTransactions(newCustomersTransactions);
            }}
          />
        </Col>{' '}
        <Col md={8}>
          <MonthlyTransactionsTrend
            app={APP_NAME}
            transactions={transactions}
            height={300}
            setModalTitle={setTransactionsModalTitle}
            setModalTransactions={setModalTransactions}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <TiersBarChart
            app={APP_NAME}
            height={400}
            accounts={payingCustomers}
            setModalTitle={setAccountsModalTitle}
            setModalAccounts={setModalAccounts}
          />
        </Col>
        <Col md={8}>
          <MonthlyRevenueLineChart
            app={APP_NAME}
            transactions={transactions}
            height={400}
            exchangeRates={exchangeRates}
            setModalTitle={setTransactionsModalTitle}
            setModalTransactions={setModalTransactions}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={8}>
          <TrialsBarChart
            app={APP_NAME}
            transactions={trialTransactions}
            setModalTitle={setTransactionsModalTitle}
            setModalTransactions={setModalTransactions}
          />
        </Col>{' '}
        <Col md={4}>
          <BigNumber
            title="Trial Conversion %"
            number={
              //prettier-ignore
              accountTrialsPurchase
								? 
									Math.round(
										// Total trials that also made a purchase
										Object.keys(accountTrialsPurchase).filter(t => accountTrialsPurchase[t].trial && accountTrialsPurchase[t].firstPurchase).length
										/
										// Total trials
										Object.keys(accountTrialsPurchase).filter(t => accountTrialsPurchase[t].trial).length
										* 
										10000
									)
									/ 100
                : 0
            }
            onClick={() => {
              setTrialConversionsTitle('Trial Conversions');
              setModalTrialConversions(accountTrialsPurchase);
            }}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={4}>
          <SourcesBarChart
            app={APP_NAME}
            height={300}
            logs={sources}
            setModalTitle={setAccountsModalTitle}
            setModalAccounts={setModalAccounts}
          />
        </Col>
      </Row>
      <Row className="mb-3">
        <Col md={12}>
          <UsageTable app={APP_NAME} accounts={payingCustomers} />
        </Col>
      </Row>
    </>
  );
};

export default CurrencyR8Report;
