import axios from 'axios';
import DowngradeAlert from 'components/billing/DowngradeAlert';
import Invoice from 'components/billing/Invoice';
import ReactivateSubAlert from 'components/billing/ReactivateSubAlert';
import PageTitle from 'components/common/PageTitle';
import { hasAppAccount } from 'helpers/utils';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Button, Card, Col, ProgressBar, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

const Billing = props => {
  const [account, setAccount] = useState(null);
  const [plan, setPlan] = useState(null);
  const [invoices, setInvoices] = useState(null);
  const [moreInvoices, setMoreInvoices] = useState(false);
  const [trialUsed, setTrialUsed] = useState(null);
  const [features, setFeatures] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('admin') == 'false') {
      navigate('/page-not-found', { replace: true });
    }
  }, []);

  useEffect(() => {
    if (plan === null) {
      return;
    }
    try {
      setFeatures(JSON.parse(plan.Description));
    } catch (e) {
      setFeatures([plan.Description]);
    }
  }, [plan]);

  useEffect(() => {
    setTrialUsed(null);
    setAccount(null);
    setPlan(null);
    setInvoices(null);
    hasAppAccount(props.app).then(trialUsed => {
      setTrialUsed(trialUsed);
      if (trialUsed == true) {
        fetchData();
      }
    });
  }, [props.app]);

  var dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  };

  const fetchData = async () => {
    await axios.post('/fetch-app-account', { app: props.app }).then(res => {
      setAccount(res.data);
      axios.post('/billing/fetch-invoices', { app: props.app }).then(res => {
        setInvoices(res.data.invoices);
        setMoreInvoices(res.data.nextpage);
      });
      // if (res.data.SubscriptionID !== null) {
      //   axios.post('/billing/fetch-invoices', { app: props.app }).then(res => {
      //     setInvoices(res.data.invoices);
      //     setMoreInvoices(res.data.nextpage);
      //   });
      // } else {
      //   setInvoices(null);
      // }
    });
    await axios
      .post('/billing/fetch-current-plan', { app: props.app })
      .then(res => {
        setPlan(res.data);
      });
  };

  const formatPrice = price => {
    price = price.toString();
    if (price.slice(-2) == '00') {
      return price.slice(0, -2);
    } else if (price == '0') {
      return price;
    } else {
      return price.slice(0, -2) + '.' + price.slice(-2);
    }
  };

  if (trialUsed === null) {
    return <PageTitle app={props.app} page={'Billing'} />;
  }

  if (trialUsed === false) {
    return <PageTitle app={props.app} page={'Plans'} />;
  }

  console.log('plan', plan);

  if (account !== null && plan !== null && invoices !== null) {
    let progressBar = Math.round(
      (account.CurrentRequests / account.MaxRequests) * 100
    );

    return (
      <>
        <PageTitle app={props.app} page={'Billing'} />
        <ReactivateSubAlert app={props.app} account={account} />
        <DowngradeAlert app={props.app} account={account} />
        {plan !== null && plan !== '' && (
          <>
            <h4>Current Plan</h4>
            <Card>
              <Card.Body>
                <Row>
                  <Col lg={8}>
                    <h4 style={{ marginBottom: 16 }}>
                      {plan.Name !== undefined ? plan.Name : 'Free Trial'}
                    </h4>
                    <p>
                      Renews on{' '}
                      {new Date(account.PaidUntil).toLocaleDateString(
                        'en-gb',
                        dateOptions
                      )}
                    </p>
                    <ul style={{ listStyle: 'none', marginLeft: '-32px' }}>
                      {features.map((feature, idx) => {
                        return (
                          <li key={idx} className="feature-list">
                            {feature.replace(
                              '{currency} ',
                              localStorage.getItem('currencySign')
                            )}
                          </li>
                        );
                      })}
                    </ul>
                  </Col>
                  <Col lg={4}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'baseline'
                      }}
                    >
                      {account.Price !== null && (
                        <>
                          <h2>
                            {localStorage.getItem('currencySign')}
                            {formatPrice(account.Price)}
                          </h2>
                          {plan.Type === 'subscription' ? (
                            <p>/month</p>
                          ) : (
                            <p>/year</p>
                          )}
                        </>
                      )}
                    </div>
                    <p style={{ marginTop: '-1rem' }}>
                      Excluding VAT where applicable.
                    </p>
                    <Button
                      onClick={() => {
                        navigate(`/${props.app}/plans`);
                      }}
                      variant="danger"
                    >
                      Upgrade
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </>
        )}
        <div style={{ marginTop: plan == '' ? 0 : 48 }}>
          <h4>Invoices</h4>
          <Card>
            <Card.Body>
              {invoices.length === 0 && <p>You do not have any invoices</p>}
              {invoices.map((invoice, idx) => {
                return <Invoice key={idx} data={invoice} />;
              })}
            </Card.Body>
          </Card>
          {/* <a href="#" hidden={moreInvoices == false}>
            Load more...
          </a> */}
        </div>
      </>
    );
  }

  return <PageTitle app={props.app} page={'Billing'} />;
};

Billing.propTypes = {
  app: PropTypes.string
};

export default Billing;
